(function() {
  const menubtn = document.getElementById("menu-button");
  if (menubtn) {
    menubtn.addEventListener("click", togglemenu);
  }

  function togglemenu() {
    document.body.classList.toggle("navopen");
  }
}());
